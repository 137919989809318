import { useAppStore } from '../store/index';

export const splitTextIntoChunks = (text, maxSize) => {
  if (!text) {
    console.error('Text is undefined or empty');
    return [];
  }

  console.log(`Splitting text into chunks of size ${maxSize}`);
  const chunks = [];
  for (let i = 0; i < text.length; i += maxSize) {
    const chunk = text.slice(i, i + maxSize);
    console.log(`Chunk ${chunks.length + 1}:`, chunk);
    chunks.push(chunk);
  }
  console.log(`Total chunks: ${chunks.length}`);
  return chunks;
};

export const processChunks = async (textChunks, model, prompt) => {
    const store = useAppStore();
    const keywords = [];
    console.log(`Processing ${textChunks.length} chunks with model ${model}`);
    for (const [index, chunk] of textChunks.entries()) {
      console.log(`Processing chunk ${index + 1}:`, chunk);
      try {
        const response = await fetch(`https:///little-smoke-e077.cloudflare-vocalist590.workers.dev/client/v4/accounts/04d7295af4ad5ea906f6db0e3e672ee2/ai/run/${model}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${store.authToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            messages: [
              { role: 'system', content: prompt },
              { role: 'user', content: chunk }
            ]
          })
        });
  
        const result = await response.json();
        console.log(result)
        if (result && result.result && result.result.response) {
          const newKeywords = result.result.response
            .split('\n')
            .filter(line => line.startsWith('$'))
            .map(line => line.replace('$', '').trim());
          console.log(`Extracted keywords from chunk ${index + 1}:`, newKeywords);
          keywords.push(...newKeywords);
        } else {
          console.warn(`No response or invalid format for chunk ${index + 1}`);
        }
      } catch (error) {
        console.error(`Error processing chunk ${index + 1}:`, error);
      }
    }
  
    console.log(`Total extracted keywords:`, keywords);
    return keywords;
  };