import { defineStore } from 'pinia';
import { defaultPrompt } from '../defaultPrompt';

export const useAppStore = defineStore('app', {
  state: () => ({
    model: '@cf/meta/llama-3-8b-instruct',
    prompt: defaultPrompt,
    chunkSize: 8000,
    authToken: '',
    usageStats: {
      words: 0,
      characters: 0,
      tokens: 0
    }
  }),
  actions: {
    setModel(newModel) {
      this.model = newModel;
    },
    setAuthToken(token) {
      this.authToken = token;
    },
    updateUsageStats(words, characters, tokens) {
      this.usageStats.words += words;
      this.usageStats.characters += characters;
      this.usageStats.tokens += tokens;
    },
    setChunkSize(newSize) {
      this.chunkSize = newSize;
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'app-store',
        storage: localStorage,
      }
    ]
  }
});