<template>
  <div id="app">
    <h1>Cloudflare AI Integration</h1>
    
    <h2>Model Selection</h2>
    <input type="text" v-model="model" @input="updateModel" />

    <h2>Prompt</h2>
    <textarea v-model="prompt" rows="4" cols="50"></textarea>

    <h2>Enter Text</h2>
    <textarea v-model="text" rows="6" cols="50"></textarea>

    <h2>Auth Token</h2>
    <input type="text" v-model="authToken" @input="updateAuthToken" />

    <h2>Chunk Size (Default 8000 characters)</h2>
    <input type="number" v-model="chunkSize" @input="updateChunkSize" />

    <button @click="processText">Send Text</button>

    <div v-if="keywords.length > 0">
      <h2>Comma-Separated Keywords:</h2>
      <textarea :value="commaSeparatedKeywords" rows="3" cols="50" readonly></textarea>
      <button @click="copyToClipboard">Copy to Clipboard</button>
    </div>

    <div>
      <h2>Usage Stats</h2>
      <p>Words: {{ usageStats.words }}</p>
      <p>Characters: {{ usageStats.characters }}</p>
      <p>Tokens: {{ usageStats.tokens }}</p>
    </div>
  </div>
</template>

<script>
import { useAppStore } from './store/index';
import { splitTextIntoChunks, processChunks } from './services/textProcessing';
import { ref, computed, watch } from 'vue';

export default {
  setup() {
    const store = useAppStore();
    const model = ref(store.model);
    const prompt = ref(store.prompt);
    const text = ref('');
    const chunkSize = ref(store.chunkSize);
    const authToken = ref(store.authToken);
    const usageStats = store.usageStats;
    const keywords = ref([]);

    const updateModel = (e) => {
      store.setModel(e.target.value);
    };

    const updateChunkSize = (e) => {
      store.setChunkSize(e.target.value);
    };

    const updateAuthToken = (e) => {
      store.setAuthToken(e.target.value);
    };

    const processText = async () => {
      if (!text.value || !authToken.value) {
        console.error('Text or Auth Token is missing');
        return;
      }
      const textChunks = splitTextIntoChunks(text.value, chunkSize.value);
      const extractedKeywords = await processChunks(textChunks, model.value, prompt.value);
      keywords.value = extractedKeywords;
    };

    const commaSeparatedKeywords = computed(() => keywords.value.join(', '));

    const copyToClipboard = () => {
      navigator.clipboard.writeText(commaSeparatedKeywords.value).then(() => {
        console.log('Keywords copied to clipboard');
      }).catch((error) => {
        console.error('Failed to copy text:', error);
      });
    };

    watch(keywords, () => {
      copyToClipboard();
    });

    return {
      model,
      prompt,
      text,
      chunkSize,
      authToken,
      usageStats,
      keywords,
      updateModel,
      updateChunkSize,
      updateAuthToken,
      processText,
      commaSeparatedKeywords,
      copyToClipboard
    };
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
